<template>
    <b-form>
      <b-row>
        <b-col>
          <h1>Livestream {{livestream.id}}</h1>
          <b-form-checkbox v-model="livestream.active" name="active" class="content-switch" switch><b>Active</b></b-form-checkbox>
        </b-col>
        <b-col cols="auto">
          <b-button
            class="mb-5 save-button"
            @click="save()"
          >
            Save
          </b-button>
        </b-col>
      </b-row>
      
      <b-tabs class="mt-3">
        <b-tab v-for="language in languages" :key="language">
          <template #title>
            <country-flag :country="isoLanguage(language)" size="normal" />
          </template>
        
        <!-- Choose platform -->
          <b-form mt="25" inline>

            <b-form-group  class="mb-2 mr-sm-2 mb-sm-0" label="Platform">
              <b-form-select v-model="livestream.type[language]" :options="livestreamPlatforms">
              </b-form-select>
            </b-form-group>
            
            <!-- EMBED CODE -->
            <b-form-group v-if="livestream.type[language] !== 'zoom'" class="mb-2 mr-sm-2 mb-sm-0" label="Livestream url">
              <b-input-group :prepend="LivestreamURLPrefix(livestream.type[language].toLowerCase())">
                <b-form-input v-model="livestream.code[language]"></b-form-input>
                <!-- <a>Where can I find this?</a> -->
              </b-input-group>
            </b-form-group>
          </b-form>
           <!-- Zoom settings -->
          <div v-if="livestream.type[language].toLowerCase() == 'zoom'">
            <b-form inline>
              <b-form-group label="API key">
                <b-form-input v-model="livestream.api_key"></b-form-input>
              </b-form-group>

              <b-form-group class="ml-5" label="API Secret">
                <b-form-input v-model="livestream.api_secret"></b-form-input>
              </b-form-group>
            </b-form>

            <b-form-group label="Password">
              <b-form-input v-model="livestream.password"></b-form-input>
            </b-form-group>

            <b-form-group label="Leave URL">
              <b-form-input v-model="livestream.leaveUrl"></b-form-input>
            </b-form-group>
          </div>
          <!-- Title / Description -->
          <b-form-group label="Title">
            <b-form-input v-model="livestream.title[language]"></b-form-input>
          </b-form-group>
          <b-form-group label="Description">
            <b-form-textarea v-model="livestream.description[language]"></b-form-textarea>
          </b-form-group>

          <h2>Chat</h2>
          <b-form-group  class="mb-2 mr-sm-2 mb-sm-0" label="Platform">
            <b-form-select v-model="livestream.chat.type[language]" :options="chatPlatforms">
            </b-form-select>
          </b-form-group>
          
          <!-- ID -->
          <b-form-group class="mb-2 mr-sm-2 mb-sm-0" label="Chat url">
            <b-input-group :prepend="ChatURLPrefix(livestream.chat.type[language].toLowerCase())">
              <b-form-input v-model="livestream.chat.code[language]"></b-form-input>
              <!-- <a>Where can I find this?</a> -->
            </b-input-group>
          </b-form-group>
        </b-tab>
      </b-tabs>
    </b-form>
</template>
<script>
export default {
  data() {
    return {
      livestreamPlatforms: [
        {value: "vimeo", text: "Vimeo"},
        {value: "youtube", text: "Youtube"},
        {value: "zoom", text: "Zoom"}
      ],
      chatPlatforms: [
        {value: "slido", text: "Slido"},
        {value: "vimeochat", text: "VimeoChat"}
      ],
      livestreamLanguageOptions: ["en", "nl"],
      showModal: false
    }
  },
  async created() {
    if (this.$route.query.id) {
      await this.doFetch()
    } else {
      this.$store.commit("Livestreams/EMPTY_CURRENT") 
    }
  },
  methods: {
    async doFetch() {
      this.isLoading = true;
      await this.$store.dispatch("Livestreams/fetchById", this.$route.query.id);
      this.isLoading = false;
    },
    async save() { 
      this.isLoading = true
      if (this.$route.query.id) {
        const index = this.livestreams.findIndex(livestream => livestream.id == this.$route.query.id)
        this.livestreams[index] = this.livestream
      } else {
        this.livestreams.push(this.livestream)
      }

      await this.$store.dispatch("Livestreams/update", this.livestreams)
      this.isLoading = false
    },
    isoLanguage(language) {
      let iso = this.$i18n.t(`languages.${language}`).toLowerCase();
      if (iso == "en") {
        iso = "us";
      }
      return iso;
    },
    LivestreamURLPrefix(type) {
      let url
      switch(type) {
        case "vimeo":
          url = "https://vimeo.com/event/"
          break;
        case "youtube":
          url = "https://www.youtube.com/embed/"
          break;
        case "zoom":
          url = ""
          break;
        default:
          url = "No livestream platform specified"
      }
      return url
    },
    ChatURLPrefix(type) {
      let url
      switch(type) {
        case "vimeochat":
          url = "https://vimeo.com/event/"
          break;
        case "slido":
          url = "https://app.sli.do/event/"
          break;
        default:
          url = "No livestream platform specified"
      }
      return url
    }
  },
  computed: {
    livestream() {
      return this.$store.state.Livestreams.livestream
    },
    livestreams() {
      return this.$store.state.Livestreams.livestreams
    },
    languages() {
      return this.$store.state.Languages.all;
    },
    // selectedLivestreamPlatform: {
    //   get() {
    //     return this.livestream.type?.toLowerCase()
    //   },
    //   set(selected) {
    //     this.livestream.type = selected.toLowerCase()
    //   } 
    // },
    // selectedChatPlatform: {
    //   get() {
    //     return this.livestream.chat?.type.toLowerCase()
    //   },
    //   set(selected) {
    //     this.livestream.chat.type = selected.toLowerCase()
    //   } 
    // },
  }
}
</script>